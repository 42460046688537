import Vue from "vue"
import VueRouter from "vue-router"
import NotFound from "@/views/404"
import NotPermission from "@/views/403"
import * as config from "../config"

Vue.use(VueRouter)

//修改 push replace 方法原型
const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}
const originalReplace = VueRouter.prototype.replace
VueRouter.prototype.replace = function replace(location) {
  return originalReplace.call(this, location).catch(err => err)
}
/**
 * menu: true 指定导航菜单路由(只能指定一个)
 * meta.title: 菜单title
 * meta.icon: 菜单图标
 * meta.permission: 权限等级 1管理员 2员工
 * meta.hidden: true 隐藏路由不在菜单显示
 * meta.keepAlive: true 缓存组件状态
 */
const routes = [
  {
    path: "/user",
    component: () =>
      import(/* webpackChunkName: "layouts" */ "@/layouts/UserLayout"),
    children: [
      {
        path: "/user",
        redirect: "/user/login"
      },
      {
        path: "/user/login",
        name: "login",
        meta: {
          title: "用户登录"
        },
        component: () =>
          import(/* webpackChunkName: "user" */ "@/views/user/Login")
      },
      {
        path: "/user/forget",
        name: "forget",
        meta: {
          title: "密码找回"
        },
        component: () =>
          import(/* webpackChunkName: "user" */ "@/views/user/Forget")
      }
    ]
  },
  {
    path: "/",
    menu: true,
    component: () =>
      import(/* webpackChunkName: "layouts" */ "@/layouts/BaseLayout"),
    children: [
      {
        path: "/",
        redirect: "/dashboard"
      },
      {
        path: "/dashboard",
        name: "dashboard",
        meta: {
          title: "仪表盘",
          icon: "dashboard"
        },
        component: () =>
          import(/* webpackChunkName: "views" */ "@/views/dashboard/Dashboard")
      },
      {
        path: "/statistics",
        name: "statistics",
        meta: {
          title: "数据分析",
          icon: "pie-chart",
          permission: 2
        },
        component: () =>
          import(
            /* webpackChunkName: "views" */ "@/views/statistics/Statistics"
          )
      },
      {
        path: "/order",
        component: { render: h => h("router-view") },
        meta: {
          title: "订单管理",
          icon: "file-sync"
        },
        children: [
          {
            path: "/order",
            redirect: "/order/list"
          },
          {
            path: "/order/create",
            name: "createOrder",
            meta: {
              title: "新增订单",
              icon: "file-done"
            },
            component: () =>
              import(/* webpackChunkName: "views" */ "@/views/order/Index")
          },
          {
            path: "/order/edit",
            name: "editOrder",
            meta: {
              title: "编辑订单",
              icon: "file-word",
              hidden: true
            },
            component: () =>
              import(/* webpackChunkName: "views" */ "@/views/order/Index")
          },
          {
            path: "/order/list",
            name: "orderList",
            meta: {
              title: "订单查询",
              icon: "file-search",
              keepAlive: true
            },
            component: () =>
              import(/* webpackChunkName: "views" */ "@/views/order/List")
          },
          {
            path: "/order/no-submit",
            name: "noSubmitOrderList",
            meta: {
              title: "未提交订单",
              icon: "exception",
              keepAlive: true
            },
            component: () =>
              import(/* webpackChunkName: "views" */ "@/views/order/NoSubmit")
          },
          {
            path: "/order/arrange",
            name: "arrangeOrder",
            meta: {
              title: "分配订单",
              icon: "file-word",
              permission: 1,
              hidden: true
            },
            component: () =>
              import(/* webpackChunkName: "views" */ "@/views/order/Arrange")
          },
          {
            path: "/order/info",
            name: "orderInfo",
            meta: {
              title: "订单详情",
              icon: "file-word",
              hidden: true
            },
            component: () =>
              import(/* webpackChunkName: "views" */ "@/views/order/Info")
          },
          {
            path: "/order/return",
            name: "orderReturn",
            meta: {
              title: "订单退改",
              icon: "file-word",
              permission: 1,
              hidden: true
            },
            component: () =>
              import(/* webpackChunkName: "views" */ "@/views/order/Return")
          },
          {
            path: "/order/complete",
            name: "completeOrder",
            meta: {
              title: "完成订单",
              icon: "file-word",
              permission: 1,
              hidden: true
            },
            component: () =>
              import(/* webpackChunkName: "views" */ "@/views/order/Complete")
          },
          {
            path: "/order/record-list",
            name: "recordList",
            meta: {
              title: "订单记录",
              icon: "file-word",
              permission: 2,
              keepAlive: true,
              hidden: true
            },
            component: () =>
              import(/* webpackChunkName: "views" */ "@/views/order/RecordList")
          },
          {
            path: "/order/record",
            name: "record",
            meta: {
              title: "新增记录",
              icon: "file-word",
              permission: 2,
              hidden: true
            },
            component: () =>
              import(/* webpackChunkName: "views" */ "@/views/order/Record")
          }
        ]
      },
      {
        path: "/settlement",
        component: { render: h => h("router-view") },
        meta: {
          title: "结算管理",
          icon: "money-collect",
          permission: 1
        },
        children: [
          {
            path: "/settlement",
            redirect: "/settlement/index"
          },
          {
            path: "/settlement/index",
            name: "orderSettlement",
            meta: {
              title: "订单结算",
              icon: "file-done",
              permission: 1,
              keepAlive: true
            },
            component: () =>
              import(/* webpackChunkName: "views" */ "@/views/settlement/Index")
          },
          {
            path: "/settlement/list",
            name: "settlementRecord",
            meta: {
              title: "结算记录",
              icon: "file-search",
              permission: 1,
              keepAlive: true
            },
            component: () =>
              import(/* webpackChunkName: "views" */ "@/views/settlement/List")
          },
          {
            path: "/settlement/entry",
            name: "profitEntry",
            meta: {
              title: "利润入账",
              icon: "dollar",
              permission: 1
            },
            component: () =>
              import(/* webpackChunkName: "views" */ "@/views/settlement/Entry")
          }
        ]
      },
      {
        path: "/product",
        component: { render: h => h("router-view") },
        meta: {
          title: "产品管理",
          icon: "file",
          permission: 1
        },
        children: [
          {
            path: "/product",
            redirect: "/product/list"
          },
          {
            path: "/product/create",
            name: "createProduct",
            meta: {
              title: "新增产品",
              icon: "file-add",
              permission: 1
            },
            component: () =>
              import(/* webpackChunkName: "views" */ "@/views/product/Index")
          },
          {
            path: "/product/edit",
            name: "editProduct",
            meta: {
              title: "编辑产品信息",
              icon: "file-word",
              permission: 1,
              hidden: true
            },
            component: () =>
              import(/* webpackChunkName: "views" */ "@/views/product/Index")
          },
          {
            path: "/product/list",
            name: "productList",
            meta: {
              title: "产品查询",
              icon: "file-text",
              permission: 1,
              keepAlive: true
            },
            component: () =>
              import(/* webpackChunkName: "views" */ "@/views/product/List")
          }
        ]
      },
      {
        path: "/hotel",
        component: { render: h => h("router-view") },
        meta: {
          title: "酒店管理",
          icon: "shop",
          permission: 1
        },
        children: [
          {
            path: "/hotel",
            redirect: "/hotel/list"
          },
          {
            path: "/hotel/create",
            name: "createHotel",
            meta: {
              title: "新增酒店",
              icon: "file-add",
              permission: 1
            },
            component: () =>
              import(/* webpackChunkName: "views" */ "@/views/hotel/Index")
          },
          {
            path: "/hotel/edit",
            name: "editHotel",
            meta: {
              title: "编辑酒店信息",
              icon: "file-word",
              permission: 1,
              hidden: true
            },
            component: () =>
              import(/* webpackChunkName: "views" */ "@/views/hotel/Index")
          },
          {
            path: "/hotel/list",
            name: "hotelList",
            meta: {
              title: "酒店查询",
              icon: "file-text",
              permission: 1,
              keepAlive: true
            },
            component: () =>
              import(/* webpackChunkName: "views" */ "@/views/hotel/List")
          }
        ]
      },
      {
        path: "/butler",
        component: { render: h => h("router-view") },
        meta: {
          title: "管家管理",
          icon: "team",
          permission: 1
        },
        children: [
          {
            path: "/butler",
            redirect: "/butler/list"
          },
          {
            path: "/butler/create",
            name: "createButler",
            meta: {
              title: "新增管家",
              icon: "file-add",
              permission: 1
            },
            component: () =>
              import(/* webpackChunkName: "views" */ "@/views/butler/Index")
          },
          {
            path: "/butler/edit",
            name: "editButler",
            meta: {
              title: "编辑管家信息",
              icon: "file-word",
              permission: 1,
              hidden: true
            },
            component: () =>
              import(/* webpackChunkName: "views" */ "@/views/butler/Index")
          },
          {
            path: "/butler/list",
            name: "butlerList",
            meta: {
              title: "管家查询",
              icon: "file-text",
              permission: 1,
              keepAlive: true
            },
            component: () =>
              import(/* webpackChunkName: "views" */ "@/views/butler/List")
          }
        ]
      },
      {
        path: "/account",
        component: { render: h => h("router-view") },
        meta: {
          title: "账号管理",
          icon: "idcard",
          permission: 1
        },
        children: [
          {
            path: "/account",
            redirect: "/account/staff/list"
          },
          {
            path: "/account/staff/create",
            name: "createStaffAccount",
            meta: {
              title: "新增员工账号",
              icon: "file-add",
              permission: 1,
              hidden: true
            },
            component: () =>
              import(
                /* webpackChunkName: "views" */ "@/views/account/staff/Index"
              )
          },
          {
            path: "/account/staff/edit",
            name: "editStaffAccount",
            meta: {
              title: "编辑员工账号",
              icon: "file-word",
              permission: 1,
              hidden: true
            },
            component: () =>
              import(
                /* webpackChunkName: "views" */ "@/views/account/staff/Index"
              )
          },
          {
            path: "/account/staff/list",
            name: "staffAccountlist",
            meta: {
              title: "员工账号管理",
              icon: "solution",
              permission: 1,
              keepAlive: true
            },
            component: () =>
              import(
                /* webpackChunkName: "views" */ "@/views/account/staff/List"
              )
          },
          {
            path: "/account/agent/create",
            name: "createAgentAccount",
            meta: {
              title: "新增分销账号",
              icon: "file-add",
              permission: 1,
              hidden: true
            },
            component: () =>
              import(
                /* webpackChunkName: "views" */ "@/views/account/agent/Index"
              )
          },
          {
            path: "/account/agent/edit",
            name: "editAgentAccount",
            meta: {
              title: "编辑分销账号",
              icon: "file-word",
              permission: 1,
              hidden: true
            },
            component: () =>
              import(
                /* webpackChunkName: "views" */ "@/views/account/agent/Index"
              )
          },
          {
            path: "/account/agent/list",
            name: "agentAccountlist",
            meta: {
              title: "分销账号管理",
              icon: "solution",
              permission: 1,
              keepAlive: true
            },
            component: () =>
              import(
                /* webpackChunkName: "views" */ "@/views/account/agent/List"
              )
          }
        ]
      },
      {
        path: "/travel-agency",
        component: { render: h => h("router-view") },
        meta: {
          title: "旅行社管理",
          icon: "global",
          permission: 1
        },
        children: [
          {
            path: "/travel-agency",
            redirect: "/travel-agency/list"
          },
          {
            path: "/travel-agency/create",
            name: "createTravelAgency",
            meta: {
              title: "新增旅行社",
              icon: "file-add",
              permission: 1
            },
            component: () =>
              import(
                /* webpackChunkName: "views" */ "@/views/travelAgency/Index"
              )
          },
          {
            path: "/travel-agency/edit",
            name: "editTravelAgency",
            meta: {
              title: "编辑旅行社信息",
              icon: "file-word",
              permission: 1,
              hidden: true
            },
            component: () =>
              import(
                /* webpackChunkName: "views" */ "@/views/travelAgency/Index"
              )
          },
          {
            path: "/travel-agency/list",
            name: "travelAgencyList",
            meta: {
              title: "旅行社查询",
              icon: "file-text",
              permission: 1,
              keepAlive: true
            },
            component: () =>
              import(
                /* webpackChunkName: "views" */ "@/views/travelAgency/List"
              )
          },
          {
            path: "/travel-agency/account",
            name: "travelAgencyAccount",
            meta: {
              title: "旅行社账号",
              icon: "file-word",
              permission: 1,
              hidden: true
            },
            component: () =>
              import(
                /* webpackChunkName: "views" */ "@/views/travelAgency/Account"
              )
          },
          {
            path: "/travel-agency/account-list",
            name: "travelAgencyAccountList",
            meta: {
              title: "旅行社账号管理",
              icon: "solution",
              permission: 1,
              keepAlive: true
            },
            component: () =>
              import(
                /* webpackChunkName: "views" */ "@/views/travelAgency/AccountList"
              )
          },
          {
            path: "/travel-agency/bill",
            name: "travelAgencyBill",
            meta: {
              title: "旅行社账单",
              icon: "file-word",
              permission: 1,
              hidden: true
            },
            component: () =>
              import(
                /* webpackChunkName: "views" */ "@/views/travelAgency/Bill"
              )
          },
          {
            path: "/travel-agency/bill-list",
            name: "travelAgencyBillList",
            meta: {
              title: "旅行社账单查询",
              icon: "audit",
              keepAlive: true
            },
            component: () =>
              import(
                /* webpackChunkName: "views" */ "@/views/travelAgency/BillList"
              )
          },
          {
            path: "/travel-agency/bill-order",
            name: "billOrderInfo",
            meta: {
              title: "订单信息",
              icon: "file-search",
              hidden: true
            },
            component: () =>
              import(
                /* webpackChunkName: "views" */ "@/views/travelAgency/BillOrder"
              )
          }
        ]
      },
      {
        path: "/account-book",
        name: "accountBook",
        meta: {
          title: "账本",
          icon: "calculator",
          permission: 2
        },
        component: () =>
          import(/* webpackChunkName: "views" */ "@/views/accountBook/Index")
      },
      {
        path: "/change-password",
        name: "changePassword",
        meta: {
          title: "修改密码",
          hidden: true
        },
        component: () =>
          import(/* webpackChunkName: "views" */ "@/views/user/ChangePassword")
      },
      {
        path: "/settings",
        component: () =>
          import(/* webpackChunkName: "layouts" */ "@/layouts/SettingLayout"),
        meta: {
          hidden: true,
          permission: 1
        },
        children: [
          {
            path: "/settings",
            redirect: "/settings/index"
          },
          {
            path: "/settings/index",
            name: "systemSettings",
            meta: {
              title: "系统设置",
              permission: 1
            },
            component: () =>
              import(/* webpackChunkName: "views" */ "@/views/settings/Index")
          },
          {
            path: "/settings/notice",
            name: "noticeSettings",
            meta: {
              title: "公告设置",
              permission: 1
            },
            component: () =>
              import(/* webpackChunkName: "views" */ "@/views/settings/Notice")
          },
          {
            path: "/settings/holiday",
            name: "holidaySettings",
            meta: {
              title: "假期设置",
              permission: 1
            },
            component: () =>
              import(/* webpackChunkName: "views" */ "@/views/settings/Holiday")
          }
        ]
      },
      {
        path: "/403",
        name: "403",
        meta: {
          title: "403",
          hidden: true
        },
        component: NotPermission
      },
      {
        path: "*",
        name: "404",
        meta: {
          title: "404",
          hidden: true
        },
        component: NotFound
      }
    ]
  },
  {
    path: "*",
    meta: {
      title: "404"
    },
    component: NotFound
  }
]

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes
})

//根据权限返回菜单路由表
export function getMenuRoutes(permission) {
  let menuRoutes
  routes.forEach(route => {
    if (route.menu) {
      let children = route.children
      if (permission == config.PERMISSION_ADMIN) {
        menuRoutes = children
      } else {
        let tmp = []
        children.forEach(item => {
          if (item.meta) {
            if (!(item.meta.permission && permission > item.meta.permission)) {
              tmp.push(item)
            }
          }
        })
        menuRoutes = tmp
      }
    }
  })
  return menuRoutes
}

export default router
