import * as config from "../config"
import router from "./index"
import store from "../store"
import * as types from "../store/vuex-types"
import { notification } from "ant-design-vue"
import NProgress from "nprogress"
import "nprogress/nprogress.css"
import { getToken, logout } from "@/utils/auth-utils"

NProgress.configure({ showSpinner: false }) // 进度条配置

const whiteList = ["/user/login", "/user/forget"] // 免登录白名单

function getPageTitle(title) {
  return config.APP_NAME + " - " + title
}

router.beforeEach(async (to, form, next) => {
  NProgress.start()
  document.title = getPageTitle(to.meta.title)
  const token = getToken()
  if (token) {
    if (whiteList.includes(to.path)) {
      NProgress.done()
      next({ path: "/" })
    } else {
      const isInit = store.getters.getState.init
      //判断是否初始化
      if (!isInit) {
        //如果没有初始化则请求服务器的初始化接口
        await store.dispatch(types.INIT, token).catch(err => {
          if (err.code == 401) {
            NProgress.done()
          } else {
            logout()
            NProgress.done()
            next({ path: "/user/login" })
          }
          return
        })
      }
      const permission = store.getters.getState.permission
      if (to.meta.permission && permission > to.meta.permission) {
        notification.error({
          message: "权限错误!",
          description: "您没有权限访问该页面"
        })
        NProgress.done()
        next({ path: "/403" })
      }
    }
  } else {
    if (whiteList.includes(to.path)) {
      next()
    } else {
      NProgress.done()
      next({ path: "/user/login" })
    }
  }
  next()
})

router.afterEach(() => {
  NProgress.done()
})
