<template>
  <a-result status="403" title="403" sub-title="对不起,您没有该操作权限!">
    <template #extra>
      <a-button type="primary" @click="goHome">
        返回首页
      </a-button>
    </template>
  </a-result>
</template>

<script>
export default {
  methods: {
    goHome() {
      this.$router.replace("/")
    }
  }
}
</script>

<style></style>
