import { TIME_OUT } from "@/config"
import axios from "axios"
import router from "../router"
import { logout, getToken } from "@/utils/auth-utils"

import { notification } from "ant-design-vue"

export default function request(config) {
  const instance = axios.create({
    baseURL: process.env.VUE_APP_BASE_URL,
    timeout: TIME_OUT
  })

  instance.interceptors.request.use(
    request => {
      const token = getToken()
      if (token) {
        request.headers.authorization = token
      }
      return request
    },
    err => {
      return Promise.reject(err.request)
    }
  )

  instance.interceptors.response.use(
    response => {
      if (response.data.code == 200) {
        return Promise.resolve(response.data)
      } else {
        return Promise.reject(response.data)
      }
    },
    err => {
      const data = err.response.data
      if (data.code && data.message) {
        notification.error({
          message: "ERROR:" + data.code,
          description: data.message
        })
        switch (data.code) {
          case 401:
            logout()
            router.replace("/user/login")
            break
          case 403:
            router.replace("/403")
            break
          case 404:
            router.replace({ name: "404" })
            break
        }
      } else {
        notification.error({
          message: "请求失败!",
          description: err.message
        })
        console.log("error:" + err.message)
      }
      return Promise.reject(data)
    }
  )

  return instance(config)
}
