import * as types from "./vuex-types"

const mutations = {
  [types.INIT](state, payload) {
    state.init = payload.init
    state.username = payload.username
    state.permission = payload.permission
    sessionStorage.setItem("init", payload.init)
    sessionStorage.setItem("username", payload.username)
    sessionStorage.setItem("permission", payload.permission)
  },
  [types.LOGOUT](state) {
    state.init = false
    state.username = undefined
    state.permission = undefined
    sessionStorage.setItem("init", false)
    sessionStorage.setItem("username", "")
    sessionStorage.setItem("permission", "")
  },
  [types.SET_VISITORS](state, visitors) {
    state.visitors = visitors
  },
  [types.CLEAR_VISITORS](state) {
    state.visitors = undefined
  }
}

export default mutations
