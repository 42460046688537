import Vue from "vue"
import App from "./App.vue"
import router from "./router"
import store from "./store"

Vue.config.productionTip = false
//css
import "normalize.css/normalize.css"
import "./assets/css/main.css"
//notification and message and model
import { notification, message, Modal } from "ant-design-vue"
Vue.prototype.$notification = notification
Vue.prototype.$message = message
Vue.prototype.$modal = Modal
//axios
import request from "./network/request"
Vue.prototype.$request = request
//cookies
import cookies from "vue-cookies"
Vue.use(cookies)
//md5
import md5 from "js-md5"
Vue.prototype.$md5 = md5
//moment
import moment from "moment"
Vue.prototype.$moment = moment
//antd
import {
  Layout,
  Button,
  Icon,
  Menu,
  Breadcrumb,
  FormModel,
  Input,
  Checkbox,
  Row,
  Col,
  Dropdown,
  Avatar,
  Spin,
  Result,
  PageHeader,
  Card,
  Table,
  Badge,
  ConfigProvider,
  Divider,
  Popconfirm,
  Tag,
  DatePicker,
  Switch,
  Select,
  InputNumber,
  Radio,
  Tooltip,
  Steps,
  Descriptions,
  Timeline
} from "ant-design-vue"
Vue.use(Layout)
Vue.use(Button)
Vue.use(Icon)
Vue.use(Menu)
Vue.use(Breadcrumb)
Vue.use(FormModel)
Vue.use(Input)
Vue.use(Checkbox)
Vue.use(Row)
Vue.use(Col)
Vue.use(Dropdown)
Vue.use(Avatar)
Vue.use(Spin)
Vue.use(Result)
Vue.use(PageHeader)
Vue.use(Card)
Vue.use(Table)
Vue.use(Badge)
Vue.use(ConfigProvider)
Vue.use(Divider)
Vue.use(Popconfirm)
Vue.use(Tag)
Vue.use(DatePicker)
Vue.use(Switch)
Vue.use(Select)
Vue.use(InputNumber)
Vue.use(Radio)
Vue.use(Modal)
Vue.use(Tooltip)
Vue.use(Steps)
Vue.use(Descriptions)
Vue.use(Timeline)
//permission
import "./router/permission"

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount("#app")
