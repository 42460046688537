import request from "../network/request"
import * as types from "./vuex-types"

const actions = {
  [types.INIT](context, token) {
    return request({
      url: "/init",
      method: "post",
      headers: {
        authorization: token
      }
    })
      .then(res => {
        const payload = {
          init: true,
          username: res.data.username,
          permission: res.data.permission
        }
        context.commit(types.INIT, payload)
        return Promise.resolve(res)
      })
      .catch(err => {
        return Promise.reject(err)
      })
  }
}

export default actions
