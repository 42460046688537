import Vue from "vue"
import store from "../store"
import * as types from "../store/vuex-types"

const TOKEN_KEY = "token"

export function login(data, auto) {
  Vue.$cookies.remove(TOKEN_KEY)
  Vue.$cookies.set(TOKEN_KEY, data.token, auto ? "7d" : "12h")
  const payload = {
    init: true,
    username: data.username,
    permission: data.permission
  }
  store.commit(types.INIT, payload)
}

export function logout() {
  store.commit(types.LOGOUT)
  Vue.$cookies.remove(TOKEN_KEY)
}

export function getToken() {
  return Vue.$cookies.get(TOKEN_KEY)
}
