const getters = {
  getState: function(state) {
    if (!state.init) {
      state.init = eval(sessionStorage.getItem("init"))
      state.username = sessionStorage.getItem("username")
      state.permission = eval(sessionStorage.getItem("permission"))
    }
    return state
  },
  getVisitors: function(state) {
    return state.visitors
  }
}

export default getters
